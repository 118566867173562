import React from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"
import { graphql } from "gatsby"

import SEO from "../components/globals/seo"
import Layout from "../components/containers/layout"
import Section from "../components/containers/section"
import Container from "../components/containers/container"
import Heading from "../components/typography/heading"
import Text from "../components/typography/text"
import Link from "../components/links/link"
import FaqBannerSmall from "../components/banners/faqBannerSmall"
import Breadcrumb from "../components/globals/breadcrumb"
import { truncateString } from "../utils/helperFunctions"

const Question = styled.li`
  margin-bottom: 36px;
  max-width: 705px;

  h2 {
    transition: ${p => p.theme.transitionDefault};
  }

  a {
    display: block;

    &:hover {
      h2 {
        color: ${p => p.theme.green};
      }
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

const FaqCategoryPage = ({ data, theme }) => {
  const pageTitle = data.allPrismicFaq.edges[0].node.data.category
  const questionsArray = data.allPrismicFaq.edges

  return (
    <Layout navbarPadOnly>
      <SEO
        title="FAQ: category"
        description="A category of frequently asked questions about the TopTekkers app."
      />

      <FaqBannerSmall />

      <Section pt={[60, null, 72]}>
        <Container>
          <Breadcrumb>
            <Link to="/faq/">FAQ</Link>
          </Breadcrumb>

          <Heading mb="28px" capitalize>
            {pageTitle}
          </Heading>

          <ul>
            {questionsArray.map((item, index) => {
              const {
                uid,
                data: { title, category, description, faq_answer },
              } = item.node

              return (
                <Question key={title.text}>
                  <Link to={`/faq/${category}/${uid}/`}>
                    <Heading
                      as="h2"
                      fontSize="22px"
                      lineHeight="38px"
                      mb="12px"
                      maxWidth="none"
                    >
                      {title.text}
                    </Heading>
                    <Text
                      fontSize={theme.smallFontSize}
                      lineHeight="28px"
                      mb="0"
                    >
                      {truncateString(faq_answer.text, 150)}
                    </Text>
                  </Link>
                </Question>
              )
            })}
          </ul>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query($activeCategory: String!) {
    allPrismicFaq(filter: { data: { category: { eq: $activeCategory } } }) {
      edges {
        node {
          uid
          data {
            category
            title {
              text
            }
            faq_answer {
              text
            }
          }
        }
      }
    }
  }
`

FaqCategoryPage.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme(FaqCategoryPage)
